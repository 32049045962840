.home {
    height: 100vh;
    width: 100vw;
    background-color: hsl(240, 100%, 6%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

@media screen and (max-width: 880px) {
    .home {
        flex-direction: column;
        justify-content: center;
        gap: 0rem;
    }
}
