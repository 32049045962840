.contact-form-container {
    width: 41%;
    display: flex;
    justify-content: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: white;
    width: 81%;
}

.contact-form input {
    height: 2.8rem;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
}

.contact-form input:focus {
    border: 2px solid rgba(0, 206, 158, 1);
}

.contact-form textarea {
    max-height: 100px;
    min-height: 100px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
}

.contact-form textarea:focus {
    border: 2px solid rgba(0, 206, 158, 1);
}

.contact-form label {
    margin-top: 1rem;
    margin-bottom: 0.2rem;
}

.contact-form input[type='submit'] {
    font-size: 1.4rem;
    margin-top: 2rem;
    cursor: pointer;
    background: rgb(249, 105, 14);
    color: white;
    border: none;
    height: 3rem;
}

.form-error-warning {
    color: rgb(255, 138, 146);
}

@media screen and (max-width: 880px) {
    .contact-form-container {
        width: 80%;
    }

    .contact-form {
        width: 90%;
    }
}
