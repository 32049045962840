.success {
    height: 100vh;
    width: 100vw;
    background-color: hsl(240, 100%, 6%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    gap: 0.5rem;
    text-align: center;
}

.success h1 {
    margin-bottom: 2.5rem;
}

.success p {
    max-width: 80%;
    margin-bottom: 1rem;
}

.back-to-form {
    font-size: 1.4rem;
    margin-top: 2rem;
    cursor: pointer;
    background: rgb(249, 105, 14);
    color: white;
    border: none;
    height: 3rem;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    outline: none;
    border-radius: 5px;
}

@media screen and (max-width: 880px) {
    .success {
        justify-content: center;
        gap: 0rem;
    }
}
